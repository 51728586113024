<template>
  <div class="documentsInformation">
    <h1 class="title">Documenten</h1>
    <h4 class="subtitle">
      Zorg ervoor dat je de juiste documenten uploadt. Als je een Belgische klant bent vragen we om je KBO-uittreksel te
      uploaden. Je kunt een voorbeeld bekijken en een nieuw document uploaden.
    </h4>
    <div class="form">
      <div>
        <div class="container">
          <div class="col">
            <h3 class="col__title">
              KvK/KBO-uittreksel
            </h3>
            <span class="col__text">
             We hebben een gewaarmerkt KvK/KBO-uittreksel van je nodig.
            <a
              href="https://www.kvk.nl/orderstraat/bedrijf-kiezen/?prefproduct=Digitaal+gewaarmerkt+uittreksel#!shop?&q=&prefproduct=Digitaal+gewaarmerkt+uittreksel"
              target="_blank"
            >
              Klik hier om het document te ondertekenen
            </a>
              van de Kamer van Koophandel en upload deze daarna hieronder.
            </span>
            <div class="col__upload">
              <a-upload-dragger
                name="kvk"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :multiple="false"
                :beforeUpload="e => beforeUpload(e, 'kvk')"
                :customRequest="e => getFile(e)"
                @change="e => handleChangeFile(e, 'kvk')"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox"/>
                </p>
                <p class="ant-upload-text">
                  Klik of sleep het bestand naar dit gebied om het te uploaden
                </p>
                <span>{{ documentsInformation.kvk.file && documentsInformation.kvk.file.name }}</span>
              </a-upload-dragger>
              <div v-if="documentsInformation.kvk.errorText.length">
                <a-badge :status="documentsInformation.kvk.errorText.length ? 'error' : 'success'"
                         v-show="documentsInformation.kvk.isDirty"
                         :text="documentsInformation.kvk.errorText.length ? 'error' : 'Goedgekeurd'"
                />
                <a-list size="small" bordered
                        v-show="documentsInformation.kvk.errorText.length"
                        style="margin-top: 15px"
                >
                  <a-list-item v-for="error in documentsInformation.kvk.errorText" :key="error">
                    <a-badge :status="'error'"
                             :text="error"
                    />
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </div>
          <div class="col">
            <h3 class="col__title">
              Directe Vertegenwoordiging document
            </h3>
            <span class="col__text">
              Om goederen te kunnen inklaren, hebben we een ingevuld Directe Vertegenwoordiging document van je nodig.
            <a
              :href="dvDownloadUrl"
            >
              Klik hier om het document te ondertekenen
            </a>
              en upload deze daarna hieronder.
            </span>
            <div class="col__upload">
              <a-upload-dragger
                name="dv"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :multiple="false"
                :beforeUpload="e => beforeUpload(e, 'dv')"
                :customRequest="e => getFile(e)"
                @change="e => handleChangeFile(e, 'dv')"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox"/>
                </p>
                <p class="ant-upload-text">
                  Klik of sleep het bestand naar dit gebied om het te uploaden
                </p>
                <span>{{ documentsInformation.dv.file && documentsInformation.dv.file.name }}</span>
              </a-upload-dragger>
              
              <div v-if="documentsInformation.dv.errorText.length">
                <a-badge :status="documentsInformation.dv.errorText.length ? 'error' : 'success'"
                         v-show="documentsInformation.dv.isDirty"
                         :text="documentsInformation.dv.errorText.length ? 'error' : 'success'"
                />
                <a-list size="small" bordered
                        v-show="documentsInformation.dv.errorText.length"
                        style="margin-top: 15px"
                >
                  <a-list-item v-for="error in documentsInformation.dv.errorText" :key="error">
                    <a-badge :status="'error'"
                             :text="error"
                    />
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </div>
        </div>
        <div v-if="errorMessage.length">
          <a-alert
            :message="errorMessage"
            type="error"
            close-text="Close Now"
            style="margin-bottom: 10px"
          />
        </div>
        <div v-if="successMessage.length">
          <a-alert
            :message="successMessage"
            type="success"
            close-text="Close Now"
            style="margin-bottom: 10px"
          />
        </div>
      </div>
      <!--      <a-button type="primary"-->
      <!--                @click="submitHandler"-->
      <!--                :disabled="isDisabled"-->
      <!--      >-->
      <!--        Opslaan-->
      <!--        <a-icon type="right"/>-->
      <!--      </a-button>-->
    </div>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  name: "DocumentsInformation",

  data() {
    return {
      loading: false,
      errorMessage: '',
      successMessage: '',
      onSuccess: false,
      documentsInformation: {
        dv: {
          file: {},
          errorText: [],
          isDirty: false
        },
        kvk: {
          file: {},
          errorText: [],
          isDirty: false
        },
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    isDisabled() {
      return (!this.documentsInformation.dv.file.size && !this.documentsInformation.kvk.file.size) ||
        (!!this.documentsInformation.dv.errorText.length || !!this.documentsInformation.kvk.errorText.length)
    },
    dvDownloadUrl() {
      return `${process.env.VUE_APP_API_URL}/api/v1/dv/download`
    }
  },
  methods: {
    submitHandler() {
      console.log('submit')
    },
    ...mapActions([
      'updateCompanyDocument'
    ]),
    getFile({onSuccess}) {
      setTimeout(() => {
        this.onSuccess = true;
        onSuccess("ok");
      }, 0)
    },
    handleChangeFile(info, title) {
      if (this.onSuccess) {
        this.documentsInformation[title].isDirty = true;
        this.onSuccess = false
        if (info.file.size > 10485760) {
          return
        } else {
          this.updateCompanyDocument({fileObj: info.file.originFileObj, title})
            .then(res => {
              if (!res.ok) {
                res.document.forEach(err => this.documentsInformation[title].errorText.push(err))
              } else {
                this.successMessage = `Congratulations! You successfully changed the ${title} document`
              }
            })
          this.documentsInformation[title].file = info.file.originFileObj
        }
      }
    },
    async beforeUpload(file, name) {
      this.documentsInformation[name].errorText = []
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.documentsInformation[name].errorText.push('The document must be smaller than 10MB!')
        return false
      }
      return !isLt10M;
    }
  }
}
</script>

<style lang="scss">
.container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.col {
  width: 35%;
  margin-right: 80px;
  &:last-child {
    margin-right: 0;
  }
}

.inputFileWrapper {
  max-height: 120px;
}

.subtitle {
  margin-bottom: 32px;
}

.col__upload {
  margin-top: 20px;
}

</style>
